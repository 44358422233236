body {
    overflow: hidden;
    background-color: rgb(247, 255, 255);
}

.root.mobile * {
    box-sizing: border-box;
}

.root.mobile {
    width: 100vw;
    height: 100%;
}

.root.mobile>div {
    width: 100vw;
    height: 100%;
}

.root.mobile .wrapper {
    width: 100vw;
    height: 100%;
}

.root.mobile img {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently                         not supported by any mobile */
}

.root.mobile a {
    text-decoration: none;
}

.root.mobile .container {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    min-width: 100vw;
    transition: 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.root.mobile .page {
    position: relative;
    height: 100%;
    width: 100vw;
    transition: 1s;
    opacity: 1;
}

.root.mobile .page.hidden {
    opacity: 0;
}

.root.mobile .page.this_page {
    opacity: 1;
    animation: visible 2s;
}

.root.mobile .page.page_left {
    left: -100vw;
}

.root.mobile .page.page_right {
    left: 100vw;
}

.root.mobile .main_comment {
    position: absolute;
    top: 2vh;
    left: 32vw;
    color: rgba(255, 255, 255, 0.719);
    display: inline-block;
    font-size: 1.8vmax;
    font-family: "Montserrat";
    z-index: 50;
    visibility: hidden;
}

.root.mobile .loading {
    position: absolute;
    width: 5%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.root.mobile .loading.on {
    opacity: 1;
    visibility: visible;
}

.root.mobile .loading img {
    width: 100%;
    visibility: inherit;
}

.root.mobile .blocking {
    position: fixed;
    top: 0;
    left: 0;
    padding: 25px 50px;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(247, 255, 255);
    z-index: 500;
}

.root.mobile .blocking.on {
    visibility: visible;
    opacity: 1;
}

.root.mobile .lock {
    max-width: 50%;
    max-height: 50%;
    margin-bottom: 20px;
}

.root.mobile .lock img {
    width: 100%;
    height: 100%;
}

.root.mobile .blocking .massage {
    font-size: 1.04vmax;
    font-family: "Montserrat";
    color: rgba(255, 255, 255, 0.857);
    text-align: center;
}

.root.mobile .blocked {
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.root.mobile .blocked.disable {
    opacity: 0;
    visibility: hidden;
}

.root.mobile .sidebar_container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}

.root.mobile .telegram.high {
    right: 4vw;
}

.root.mobile .insta.high {
    right: 4vw;
    top: 14.2vmax;
}

.root.mobile .social_media.high {
    height: fit-content;

    width: 2.5vmax;
}

.root.mobile .social_media.high img {
    height: unset;
    width: 100%;
}

.root.mobile .youtube.high {
    right: 3.5vw;
    width: 2.9vmax;
    top: 8vmax;
}

.root.mobile .location.high {
    top: 20.2vmax;
    right: 4vw;

}

.root.mobile .social_media {

    top: 2.2vmax;
    position: absolute;
    display: block;
    width: 2.5vmax;
    margin: 1.04vmax;
    transition: 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);
    
    z-index: 20;
}

.root.mobile .social_media img {
    opacity: 0.8;
    transform: scale(1);
    transition: 0.2s;
}

.root.mobile .social_media:active img {
    transform: scale(1);
    opacity: 0.8;
}

.root.mobile .social_media img {
    width: 100%;
    pointer-events: none;
}

.root.mobile .youtube {
    padding: 0.04vw 0;
    width: 3.2vmax;
    /* transition: 0.8s 0.4s; */
    right: 17.5vw;
}

.root.mobile .location {
    right: 41.8vw;
    /* transition: 0.8s; */
    top: 2vmax;
    width: 2.3vmax;
}

.root.mobile .telegram {
    /* transition: 0.8s 0.6s; */
    right: 6vw;
}

.root.mobile .insta {
    /* transition: 0.8s 0.2s; */
    right: 30.5vw;
}

.root.mobile .content {
    z-index: inherit;
}

.root.mobile .home .name {
    position: absolute;
    top: 28%;
    left: 12%;
    z-index: 1;
}

.root.mobile .home .name .title {
    font-size: 4.8vmax;
    color: rgba(255, 255, 255, 0.8);
    font-family: "Montserrat";

    letter-spacing: 0.42vmax;
    z-index: 8;
    transition: 0.8s 1s;
    transform: translate(0, 70%);
    opacity: 0;
}

.root.mobile .home .name span {
    margin-top: 0.48vmax;
    display: block;
    width: 100%;
    height: 0.104vmax;
    transform: scale(0);
    background-color: white;
    transition: 1s;
}

.root.mobile .home .name .subtitle {
    font-size: 1.88vmax;
    text-align: center;
    margin-top: 0.84vmax;
    font-family: "Montserrat";
    letter-spacing: 0.104vmax;
    color: rgba(255, 255, 255, 0.73);
    z-index: 1;
    transform: translate(0, -100%);
    opacity: 0;
    transition: 1s 1.8s;
}

.root.mobile .home .name.ready .title {
    transform: translate(0, 0);
    opacity: 1;
}

.root.mobile .home .name.ready span {
    transform: scale(1);
}

.root.mobile .home .name.ready .subtitle {
    transform: translate(0, 0);
    opacity: 1;
}

.root.mobile .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.42);
}

.root.mobile .home {
    height: 100%;
}

.root.mobile .feedbacks {
    position: absolute;
    bottom: 14vmin;
    left: 3%;
    width: 13vmax;
    z-index: 10;
}

.root.mobile .feedbacks img {
    width: 100%;
}

.root.mobile .win_logo {
    width: 12.3vmax;
    height: 12.3vmax;
    padding: 1.26vmax;
    position: fixed;
    transition: 0.4s cubic-bezier(0.215, 0.610, 0.355, 1);

    left: 0;
    right: 0;
    z-index: 20;
}

.root.mobile .win_logo img {
    width: 100%;
    height: 100%;
}

.root.mobile .contact {
    bottom: 15vmin;
    right: 4vw;
    position: absolute;
    width: 9vmin;
    height: 9vmin;
    z-index: 20;
}

.root.mobile .contact img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.root.mobile .contacts {
    position: absolute;
    width: 58vw;
    font-size: 1.9vmax;
    min-height: 5vh;
    color: #fff;
    padding: 2vmax 4vmax;
    background-color: rgba(32, 32, 36, 0.809);
    bottom: 130%;
    right: 0%;
    border-radius: 0.6vmax;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
}

.root.mobile .contact.opened .contacts {
    opacity: 1;
    visibility: visible;
}

.root.mobile .contact_list li {
    margin-bottom: 1.5vmax;
    position: relative;
}

.root.mobile .contact_list li input {
    display: none;
}

.root.mobile .contact_list :last-child {
    margin-bottom: 0;
}

.root.mobile .contact_list li span {
    position: absolute;
    top: 0%;
    right: -15%;
    display: block;
    width: 2.1vmax;
    height: 2.1vmax;
    z-index: 5;
}

.root.mobile .contact .contact_list li span img.copy_button {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
}

.root.mobile .contact .contact_list li span img.copied_button {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.root.mobile .contact .contact_list li.copied span img.copy_button {
    opacity: 0;
    visibility: hidden;
}

.root.mobile .contact .contact_list li.copied span img.copied_button {
    opacity: 1;
    visibility: visible;
}




.root.mobile .lang {
    position: absolute;
    width: 9vmin;
    height: 9vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 28vmin;
    right: 4.2vw;
    z-index: 15;
}

.root.mobile .lang>span {
    color: white;
    opacity: 0.7;
    transition: 0.4s;
    cursor: pointer;
    font-size: 10vmin;
    transform: scale(1);

}

.root.mobile .lang.active>span {
    opacity: 1;
    transform: scale(1.08);
}

.root.mobile .lang_list {
    position: absolute;
    right: 0;
    bottom: 110%;
    min-height: 120%;
    width: 50vw;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.503);
    color: white;
    visibility: hidden;
}

.root.mobile .lang_list.visible {
    visibility: visible;
}

.root.mobile .lang_block {
    list-style: none;
    color: rgba(255, 255, 255, 0.866);
    min-height: 50%;
    padding: 0.8vmax 1.5vmax;
    font-family: "Montserrat";
    font-size: 1.7vmax;
    letter-spacing: 1px;
    transition-delay: 0;
    cursor: pointer;
    pointer-events: fill;
}



/* .root.mobile .location
{
    bottom: 6.3%;
    left: 0;
    width: 100%;
    padding: 0 5vw;
    text-align: end;
    position: absolute;
    opacity: 0.7; 
    z-index: 18;
        document.querySelector(".root").style.cssText = `height: ${window.innerHeight}px`;
    cursor: pointer; 
    transition: 0.3s 1.5s;
}
.location_link 
{
    display: inline-flex;
    font-size: 1.5vmax;
    align-items: center;
    color: white;
    transform: scale(1);
    text-decoration: none;
}
.root.mobile .location_link span 
{
    font-size: 1.56vmax;
    padding-bottom: 0.208vw;
    margin-right: 0.156vw;
    text-decoration: none;
}
.root.mobile .location_link:active
{
    opacity: 0.7;
    transform: scale(1);
}
.root.mobile .location.hidden
{
    opacity: 0;
    transition: 0.3s 0s;
} */

/*----- UNIVERSAL ----- */

.root.mobile .clickable {
    opacity: 0.7;
    transform: scale(1);
    transition: 0.2s;
}



/* ---------------- */
.root.mobile .background img {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: -70vmin;
}

.root.mobile .background#b_home {
    min-width: 115vw;
    height: 130vh;
    animation-name: action;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    animation-timing-function: linear;
    position: absolute;
    top: -25vh;
    left: -5vw;
    z-index: -1;
}

.root.mobile .background#b_services img {
    transform: rotateY(180deg);
    min-width: 130%;
    height: 130%;
}

.root.mobile .background#b_services img {
    left: -100vmin;

}

.root.mobile .background#b_services {
    min-width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    top: 0;
    opacity: 1;
    left: 0;
}

.root.mobile .background#b_staff {
    min-width: 115vw;
    height: 120vh;
    position: absolute;
    top: -10vh;
    opacity: 1;
    left: -10vw;
}

/* --- services --- */

.root.mobile .services {
    padding: 0 2.25vw;
    padding-bottom: 6.5vmax;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
}

.root.mobile .services .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.622);
}

.root.mobile .services_block {
    width: 95vmin;
    height: 30%;
    text-align: center;
    display: flex;
    position: absolute;
    top: 15vh;
    justify-content: center;
    align-items: flex-start;
}

.root.mobile .service {
    z-index: 1;
    height: 100%;
    margin: 0.52vw 2.08vw;
    width: 100%;
    padding: 2vh 15%;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: 0.2s cubic-bezier(0.35, 1.24, 0, 0.96);
}

.root.mobile .title {
    color: rgba(14, 119, 239, 0.501);
    font-size: 1.8vmax;
    white-space: nowrap;
    font-family: "Montserrat";
    text-align: center;
    transform: translate(0, 120%);
    opacity: 0;
    margin: 1.04vw 0px;
    transition: 0.2s 0.3s cubic-bezier(0.35, 1.24, 0, 0.96);
}

.root.mobile .line_x {
    display: block;
    min-height: 0.156vmax;
    width: 0%;
    margin: 1.5vh 0;
    background-color: rgba(255, 255, 255, 0.886);
    transition: 0.2s 0.2s cubic-bezier(0.35, 1.24, 0, 0.96);
    z-index: 2;
}

.root.mobile .service .list {
    color: rgba(255, 255, 255, 0.785);
    padding: 0 1.56vmax;
    opacity: 0;
    text-align: start;
    transform: translate(0, -100%);
    transition: 0.2s 0.4s cubic-bezier(0.35, 1.24, 0, 0.96);
}

.root.mobile .service .list li {
    list-style-type: square;
    font-family: "Montserrat";
    letter-spacing: 1px;
    font-size: 1.344vh;
    margin: 1.3vh 0;

}

.next_button_s {
    width: 8.2vmin;
    position: absolute;
    top: calc(50% - 2.6vw);
    z-index: 25;
    right: 2vw;
    transition: 1s 0.3s;
    cursor: pointer;
    opacity: 1;
    animation-name: moveX;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
}

.root.mobile .next_button_s img {
    width: 100%;
    transform: scale(1);
    opacity: 0.6;
    transition: 0.3s 0.2s;
}

.root.mobile .next_button_s img:active {
    transform: scale(1.1);
    opacity: 1;
    transition: 0.3s 0s;
}

.prev_button_s {
    width: 8.2vmin;
    position: absolute;
    top: calc(50% - 2.6vw);
    z-index: 25;
    left: 2vw;
    transition: 1s 0.3s;
    cursor: pointer;
    opacity: 1;
    animation-name: moveXLeft;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
}

.root.mobile .prev_button_s img {
    width: 100%;
    transform: rotate(180deg) scale(1);
    opacity: 0.6;
    transition: 0.3s 0.2s;
}

.root.mobile .prev_button_s img:active {
    transform: rotate(180deg) scale(1.1);
    opacity: 1;
    transition: 0.3s 0s;
}

.root.mobile .service.on {
    opacity: 1;
    visibility: visible;
}

.root.mobile .service.on .title {
    transform: translate(0, 0);
    opacity: 1;
}

.root.mobile .service.on .line_x {
    width: 100%;
}

.root.mobile .service.on .list {
    opacity: 1;
    transform: translate(0, 0);
}

.root.mobile .priceList_container {
    width: 100%;
    height: 42%;
    position: absolute;
    text-align: center;
    transition: 0.7s;
    bottom: 9vh;
    left: 0;
    overflow: hidden;
}

.root.mobile .priceList_table {
    width: 90%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 5%;
    padding: 1.56vmax 0.54vmax;
    background-color: rgba(0, 0, 0, 0.789);
    border-top: 0.156vmax solid rgba(14, 119, 239, 0.586);
    border-bottom: 0.156vmax solid rgba(14, 119, 239, 0.586);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.7s;
    overflow-y: scroll;
    z-index: 50;
}

.root.mobile .priceList_table.view {
    opacity: 1;
    visibility: visible;
}

.root.mobile .priceList_container.openlist {
    height: 87%;
}

.root.mobile .part {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.root.mobile .t_title {
    color: rgb(65, 156, 216);
    width: 100%;
    font-size: 1.456vmax;
    font-family: "Montserrat";
    text-align: center;
    margin: 1.04vmax 0px;
}

.root.mobile .line {
    width: 100%;
    padding: 0px 1.04vmax;
    font-size: 1.04vmax;
    margin: 0.78vmax 0;
    color: rgba(255, 255, 255, 0.849);
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    letter-spacing: 1px;
    overflow: hidden;
}

.root.mobile .l_service>span {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.root.mobile .l_service {
    z-index: 1;
    max-width: 50%;
}

.root.mobile .priceList_table .bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2.3vmax 0 1vmax;
    font-size: 1vmax;
    font-weight: bold;
}

.root.mobile .priceList_table .bar :first-child {
    color: rgb(65, 156, 216);
}

.root.mobile .priceList_table .bar :last-child {
    color: rgb(185, 66, 66)
}

.root.mobile .price {
    padding: 0 0vmax 0 0;
    min-width: 6vmax;
    text-align: start;
    white-space: nowrap;
    z-index: 1;
}

.root.mobile .price.urgent {
    text-align: center;
    padding: 0 0vmax 0 0vmax;
}

.root.mobile .line>span {
    overflow: hidden;
    color: rgba(14, 119, 239, 0.578);
    text-overflow: clip;
    display: block;
    min-width: 5%;
}

.root.mobile .priceList_table::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.386);
    width: 0.416vmax;

}

.root.mobile .priceList_table::-webkit-scrollbar-button {
    display: none;
}

.root.mobile .priceList_table::-webkit-scrollbar-thumb {
    background-color: rgba(14, 119, 239, 0.586);

}

.root.mobile .open {
    text-align: center;
    display: inline;
    width: min-content;
    white-space: nowrap;
    text-align: center;
    font-size: 2.08vmax;
    font-family: 'Montserrat';
    letter-spacing: 0.078vmax;
    text-transform: uppercase;
    color: white;
    margin: 1.144vmax;
    transition: 1s;
    animation: blink 2.5s;
    text-shadow: 0 0.156vmax 0.208vmax rgba(14, 119, 239, 0.94);
    cursor: pointer;
    animation-iteration-count: infinite;
    z-index: 3;
}

/* --- staff --- */

.root.mobile .staff {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.root.mobile .fog {
    height: 70vmax;
    opacity: 0.4;
    transition-timing-function: ease-in-out;
    position: absolute;
    z-index: 15;
    pointer-events: none;
}

.root.mobile .fog img {
    height: 100%;
}

.root.mobile .fog._1 {
    top: 60vh;
    transition: 1s;
    left: -90vmax;
}

.root.mobile .fog._2 {
    transition: 1s 0.3s;
    top: 70vh;
    left: calc(50% - 70vmax);
}

.root.mobile .fog._3 {
    top: 55vh;
    transition: 1s;
    right: -80vmax;
}

.root.mobile .fog._1.off {
    top: 120vh;
    left: -40%
}

.root.mobile .fog._2.off {
    top: 120vh;
}

.root.mobile .fog._3.off {
    top: 120vh;
    right: -40%;
}

/*----- defolt dentists ------*/

.root.mobile .dentists {
    z-index: 8;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.root.mobile .dentists_photos {
    z-index: 8;
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
}

.root.mobile .dentist {
    transition: 0.5s cubic-bezier(0.92, 0.05, 0.27, 0.91);
    height: 100% !important;
    position: absolute;
    opacity: 1;
    transform: translate(0, 100%);
}

.root.mobile .dentist.on {
    transform: translate(0, 0);
}

.root.mobile .dentist img {
    height: 100%;
}


/*---- BACK BUTTON ----*/


.root.mobile .dentist_information {
    width: 100%;
    position: absolute;
    padding: 0.1vmax 3vw;
    top: 13%;
    left: 0;
    max-height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    visibility: hidden;
    overflow: hidden;
    pointer-events: none;
    z-index: 1;
}

.root.mobile .dentist_information>span {
    width: 0%;
    min-height: 0.156vmax;
    margin: 0.78vmax 0;
    transition: 0.5s 0s;
    background-color: rgba(255, 255, 255, 0.663);
}

.root.mobile .dentist_information .dentist_name {
    width: 100%;
    text-align: center;
    transform: translate(0, -50%);
    opacity: 0;
    transition: 0.2s 0.1s cubic-bezier(0.35, 1.24, 0, 0.96);
    font-size: 1.52vmax;
    color: rgba(255, 255, 255, 0.855);
    letter-spacing: 0.156vmax;
    visibility: hidden;
    font-family: "MonserratB";
}

.root.mobile .dentist_information .text {
    width: 100%;
    overflow-y: scroll;
    visibility: hidden;

    padding: 0.52vmax 0.78vmax;

}

.root.mobile .dentist_information .text>span {
    width: 100%;
    max-height: 100%;
    line-height: 1.8vmax;
    display: block;
    font-size: 1.3vmax;
    text-align: left;
    font-family: 'Montserrat';
    color: rgba(255, 255, 255, 0.795);
    height: 100%;
    opacity: 1;
    transform: translate(0, -110%);
    transition: 0.6s 0.2s cubic-bezier(0.35, 1.24, 0, 0.96);
    overflow: hidden;
    visibility: hidden;
    text-overflow: ellipsis;
}

.root.mobile .dentist_information .text>span static {
    text-align: left;
}

.root.mobile .dentist_information.period {
    visibility: visible;
    pointer-events: fill;
    z-index: 5;
}

.root.mobile .dentist_information.period>span {
    width: 100%;
    transition: 0.6s 0.2s cubic-bezier(0.35, 1.24, 0, 0.96);
}

.root.mobile .dentist_information.period .text {
    visibility: visible;
}

.root.mobile .dentist_information.period .text>span {
    transform: translate(0, 0);
    visibility: visible;
    transition: 1s 0.3s cubic-bezier(0.35, 1.24, 0, 0.96);
}

.root.mobile .dentist_information.period .dentist_name {
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
    transition: 0.7s 0.2s cubic-bezier(0.35, 1.24, 0, 0.96);
}

.root.mobile .next_button {
    width: 8.2vmin;
    position: absolute;
    top: calc(50vh - 2.6vmin);
    z-index: 25;
    right: 3%;
    transition: 1s 0.3s;
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    animation-name: moveX;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
}

.root.mobile .next_button img {
    width: 100%;
    transform: scale(1);
    opacity: 0.8;
    transition: 0.3s;
}

.root.mobile .next_button.hidden {
    opacity: 0;
    visibility: hidden;
}

.root.mobile .prev_button {
    width: 8.2vmin;
    position: absolute;
    top: calc(50vh - 2.6vmin);
    z-index: 25;
    left: 3%;
    transition: 1s 0.3s;
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    animation-name: moveXLeft;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
}

.root.mobile .prev_button img {
    width: 100%;
    transform: rotate(180deg) scale(1);
    opacity: 0.8;
    transition: 0.3s;
}

.root.mobile .prev_button.hidden {
    opacity: 0;
    visibility: hidden;
}

.root.mobile .prev_button:active img {
    opacity: 1;
    transform: scale(0.9) rotate(180deg);
}

.root.mobile .next_button:active img {
    opacity: 1;
    transform: scale(0.9);
}

/* ------------- INFO -------------- */

.root.mobile .info {
    height: 100%;
    overflow-y: scroll;
}

.root.mobile .info::-webkit-scrollbar {
    width: 0.6vw;
    background-color: rgb(32 32 36);
    z-index: 10;
    display: none;
}

.root.mobile .info::-webkit-scrollbar-thumb {
    background-color: rgba(54, 54, 60, 0.737);
}

.root.mobile .info .background {
    width: 100%;
    height: 100%;
    background-color: rgb(32 32 36);
    opacity: 1 !important;
    position: absolute;
    top: 0;
    left: 0;
}

.root.mobile .info .info_back {
    position: relative;
    overflow: hidden;
    z-index: 3;
    width: 100%;
    height: 45%;
}

.root.mobile .info .info_back img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

.root.mobile .info .info_back .shadow {
    width: 100%;
    height: 150%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(18, 27, 30, 0.667);
}

.root.mobile .info .main_t {
    font-size: 4.5vmax;
    color: white;
    font-family: "MarkScript" !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 5%;
    z-index: 3;
}

.root.mobile .info .information {
    width: 100%;
    position: absolute;
    top: 40%;
    min-height: 100%;
    z-index: 3;
    border-top-right-radius: 2vmax;
    border-top-left-radius: 2vmax;
    background-color: rgb(228, 239, 242);
}

.root.mobile .info .chapter {
    width: 100%;
    padding: 2.5vmax 2vmax;
}

.root.mobile .info .chapter_title {
    font-family: "Andrika";
    width: 100%;
    padding: 0 0 2vmin;
    font-size: 3.2vmax;
    text-align: center;
    color: rgb(58, 56, 56);
}

.root.mobile .info .article {
    width: 100%;
    margin: 4vw 0;
}

.root.mobile .info .article_img {
    width: 45%;
    background-color: rgba(31, 7, 7, 0.116);
    display: flex;
    align-items: center;
    margin: 1vw 2vw;
    border: 1vw solid transparent;
    border-radius: 1vw;
    float: left;
}

.root.mobile .info .article_img img {
    width: 100%;
    border-radius: 1vw;

}

.root.mobile .info .article.right .article_img {
    float: right;
}

.root.mobile .info .atext {
    font-size: 1.3vmax;
    color: rgb(36, 36, 36);
    letter-spacing: 0.1vmax;
}


/* ------------- BLOG -------------- */

.root.mobile .back_photo {
    width: 100%;
    position: absolute;
    top: -5%;
    left: 0;
    overflow: hidden;
}

.root.mobile .back_photo .shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.557);
}

.root.mobile .back_photo img {
    width: 120%;
    transform: translate(-10%, 0);
}

.root.mobile .blog_list {
    width: 100%;
    height: 72%;
    background-color: rgb(32 32 36);
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    border-top-left-radius: 8vw;
    border-top-right-radius: 8vw;
}

.root.mobile .blog .btitle {
    padding: 2vh 5vw;
    text-align: center;
    font-size: 4.1vmax;
    color: #fff;
    font-family: "Lobster";
}

.category_list {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    transition: 0.7s;
    top: 0;
}

.root.mobile .blog_container {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vh 10vw;
}

.root.mobile .category {
    width: 100%;
    margin: 0 0 3vh;
    display: block;
}

.root.mobile .category_photo {
    width: 100%;
    height: 20vh;
    border-radius: 1vh;
    position: relative;
    background-color: #bfc5d73f;
    margin: 10px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    transform: scale(1);
    transition: 0.3s;
}

.root.mobile .category_photo img {
    width: 100%;
    min-height: 100%;
}

.root.mobile .category_sahdow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.444);
    transition: 0.3s;
    z-index: 1;
}

.root.mobile .photo_text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.708);
    font-size: 9vw;
    z-index: 2;
    transition: 0.3s;
    pointer-events: none;
}

.root.mobile .category_photo:active {
    transform: scale(1.03);
}

.root.mobile .category_photo:active .category_sahdow {
    background-color: rgba(0, 0, 0, 0.245);
}

.root.mobile .category_photo:active .photo_text {
    color: rgba(255, 255, 255, 0.912);
}

.root.mobile .description {
    color: white;
    margin: 15px 5px 0;
    font-size: 1.6vmax;
}

.root.mobile .blog .content {
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    padding: 1vh 0 5vh;
    transition: 0.7s;
    background-color: rgb(32 32 36);
    z-index: 100;
}

.root.mobile .blog .content.on {
    right: 0;
}

.root.mobile .pub_container {
    width: 100%;
    height: 95%;
}

.root.mobile .pub_back {
    width: 3.2vmax;
    position: absolute;
    top: 3.52vmax;
    left: 5%;
    z-index: 8;
}

.root.mobile .pub_back img {
    width: 100%;
}

.root.mobile .sellected_title {
    padding: 2vmax 4vmin;
    text-align: center;
    font-family: "Lobster";
    color: #fff;
    font-size: 3.5vmax;
}

.root.mobile .publications {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 6vh 4vw 0;
    display: flex;
    flex-direction: column;
    background-color: rgb(32 32 36);
    position: relative;
    z-index: 1;
}

.root.mobile .pub {
    width: 100%;
    background-color: rgb(27, 27, 31);
    border-radius: 2vmax;
    color: rgba(237, 243, 245, 0.769);
    box-shadow: 0 0vw 0.7vw rgba(63, 63, 63, 0.328);
    padding: 2.5vh 5vmin;
    margin: 3.2vmin 0;
    display: flex;
    flex-direction: column;
}

.root.mobile .pub_title {
    font-size: 3vmax;
}

.root.mobile .pub_photos[type=video] {
    width: 100%;
    background-color: rgb(34, 34, 39);
    border-radius: 1.3vmax;
    margin: 4vmin 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}

.root.mobile .pub_photos[type=video] video {
    max-width: 100%;
    max-height: 100%;
}

.root.mobile .pub_photos[type=swiper] {
    width: 100%;
    background-color: rgb(34, 34, 39);
    border-radius: 1.3vmax;
    margin: 4vmin 0;
    position: relative;
    overflow: hidden;
    padding: 3% 0;
}

.root.mobile .prev_slide {
    width: 3vmax;
    position: absolute;
    top: calc(50% - 1.5vmax);
    left: 0;
    z-index: 5;
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1);
    margin-left: 1vw;
    opacity: 0.6;
}

.root.mobile .prev_slide img {
    width: 100%;
    transform: rotate(180deg);
}

.root.mobile .next_slide {
    width: 3vmax;
    position: absolute;
    top: calc(50% - 1.5vmax);
    right: 0;
    z-index: 5;
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1);
    margin-right: 1vw;
    opacity: 0.6;
}

.root.mobile .next_slide:active,
.root.mobile .prev_slide:active {
    transform: scale(1.05);
    opacity: 0.85;
}

.root.mobile .next_slide img {
    width: 100%;
}

.root.mobile .swiper-wrapper {
    box-sizing: border-box;
    align-items: center;
    transition: 0.3s !important;
    opacity: 1;
}

.root.mobile .swiper-wrapper .pub_photo {
    width: 45%;
    height: auto !important;
}

.root.mobile .pub_photo {
    border-radius: 0.7vw;
    filter: brightness(0.6);
    transform: scale(0.95);
}

.root.mobile .swiper-wrapper.active .pub_photo {
    transition: 0.5s;

}

.root.mobile .pub_photo.active_slide {
    transform: scale(1.05);
    filter: brightness(1);
}

.root.mobile .pub_text {
    font-size: 1.2vmax;
    color: rgba(255, 255, 255, 0.718);
    text-align: justify;
}

.root.mobile .pub_nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2vw;
    align-items: center;
    margin: 1.2vh 0 0;
}

.root.mobile .pub_nav .like {
    width: 13%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.root.mobile .pub_nav .like img {
    width: 2vmax;
    padding-bottom: 0.2vh;
    opacity: 0.25;
    transition: 0.2s;
    cursor: pointer;
}

.root.mobile .pub_nav .like img[status="on"] {
    opacity: 0.8;

}

.root.mobile .pub_nav .like img:hover {
    opacity: 0.65;
}

.root.mobile .pub_nav .like .count {
    padding-top: 0.4vh;
    color: rgba(241, 250, 255, 0.561);
    font-size: 1.9vmax;
}

.root.mobile .pub_nav .comments {
    height: 100%;
}

.root.mobile .pub_nav .comments img {
    width: 2vmax;
    transition: 0.2s;
    cursor: pointer;
    opacity: 0.5;
}

.root.mobile .pub_nav .comments img:hover {
    opacity: 0.8;
}

.root.mobile .content .pre_text {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    color: rgba(51, 51, 58, 0.628);
    ;
    font-size: 2.6vmax;

}

.root.mobile .blog_view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 150;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
}

.root.mobile .blog_view.show {
    visibility: visible;
    opacity: 1;
}

.root.mobile .blog_view .images {
    display: flex;
    width: 100%;
}

.root.mobile .blog_view .prev_slide {
    width: 8vmax;
    position: absolute;
    top: calc(50% - 4vmax);
    left: 0;
    z-index: 5;
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1);
    margin-left: 1vw;
    opacity: 0.6;
}

.root.mobile .blog_view .prev_slide img {
    width: 100%;
    transform: rotate(180deg);
}

.root.mobile .blog_view .next_slide {
    width: 8vmax;
    position: absolute;
    top: calc(50% - 4vmax);
    right: 0;
    z-index: 5;
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1);
    margin-right: 1vw;
    opacity: 0.6;
}

.root.mobile .blog_view .next_slide:active,
.root.mobile .blog_view .prev_slide:active {
    transform: scale(1.05);
    opacity: 0.85;
}

.root.mobile .blog_view .next_slide img {
    width: 100%;
}

.root.mobile .blog_view .image {
    width: 100%;
    height: 100%;
    padding: 10% 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    position: absolute;
    transition: 0.4s cubic-bezier(0.14, 0.88, 0.24, 0.98);
    top: 0;
    left: 100%;
    z-index: 2;
}

.root.mobile .blog_view .image.before {
    left: -100%;
}

.root.mobile .blog_view .image.after {
    left: 100%;
}

.root.mobile .blog_view .image.active {
    left: 0;
}

.root.mobile .blog_view .image img {
    max-width: 100%;
    max-height: 100%;
}

.root.mobile .view_back {
    width: 5vmax;
    height: 5vmax;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 2vmax;
    top: 2vmax;
    z-index: 15;
}

.root.mobile .blog_view .blog_shadow {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.676);
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.root.mobile .view_back::before,
.root.mobile .view_back::after {
    content: '';
    width: 110%;
    height: 0.4vmax;
    position: absolute;
    background-color: aliceblue;
}

.root.mobile .view_back::before {
    transform: rotate(45deg);
}

.root.mobile .view_back::after {
    transform: rotate(-45deg);
}

.root.mobile .avatar {
    position: relative;
    width: 5vh;
    height: 5vh;
    overflow: hidden;
    background-color: rgb(34, 34, 39);
    border-radius: 50%;
    float: left;
    margin: 0vw 1vh 0.5vh 0vw;
}

.root.mobile .avatar.no_ava {
    padding-top: 0.5vw;
}

.root.mobile .avatar.no_ava img {
    filter: opacity(0.5);
}


.root.mobile .avatar img {
    width: 100%;
}

.root.mobile .author_name {
    color: rgb(135, 139, 139);
    text-transform: capitalize;
    font-size: 1.2vh;
    padding: 2vh 0;
    margin-bottom: 1vh;
}

/* --- sidebar --- */


.root.mobile .sidebar {
    width: 100%;
    height: 5.1vmax;
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.516);
    left: 0;
    bottom: 0;
    transition: 0.8s;
    z-index: 100;
    box-shadow: 0 0.156vmax 0.208vmax rgba(255, 255, 255, 0.573);
}



.root.mobile .side_nav {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.root.mobile .side_nav>span {
    position: absolute;
    bottom: 0;
    height: 0.254vh;
    transition: 0.5s;
    box-shadow: 1px 0px 5px 0px rgb(0, 0, 0);
    background-color: rgb(80, 181, 249);
}

.root.mobile .side_nav .block {
    width: 15%;
    height: 100%;
    margin: 0.52vw;
    padding: 1.4vmax 1vmax;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
}

.root.mobile .side_nav .block.active {
    background-color: rgba(0, 0, 0, 0.231);
}

.root.mobile .side_nav .block img {
    height: 100%;
    transition: 0.2s;
    opacity: 0.6;
    transform: scale(1);
    pointer-events: none;
}

.root.mobile .side_nav .block:active img {
    opacity: 0.6;
    transform: scale(1);
}

.root.mobile .side_nav .block.active img {
    opacity: 1;
    transform: scale(1.2);
}

.root.mobile .side_nav .block._1.active img {
    opacity: 1;
    transform: scale(1.15);
}

.root.mobile .side_nav .block._1 {
    padding: 1.35vmax 1vmax;
}


.root.mobile .sidebar.paralax {
    left: -3.1vw;
}

.root.mobile .sidebar.hover {
    left: 0% !important;
}




@media (max-width: 1000px) {
    /* body 
    {
        padding-bottom: 5vh;
    }
    .sidebar
    {
        width: 100vw;
        height: 5vh;
        display: flex;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.516);
        left: 0;
        bottom: 0;
        transition: 0.8s;
        z-index: 20;
        box-shadow: 0 0.156vw 0.208vw rgba(255, 255, 255, 0.573);
    }
    .side_nav
    {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .side_nav .block
    {
        width: 8%;
        height: 100%;
        margin: 0.52vw;
        padding: 2.5vw;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.5s;
    }
    .side_nav .block._1
    {
        padding: 2.5vw 2.7vw;
    } */
}

/*------------- OPEN -------------*/

@keyframes openDMansur {
    0% {
        left: 9%;
        bottom: -100%;
    }

    100% {
        left: 9%;
        bottom: -35%;
    }
}

@keyframes openDDirector {
    0% {
        bottom: -100%;
        left: 40%;
    }

    100% {
        bottom: -35%;
        left: 40%;
    }
}

@keyframes openDXursand {
    0% {
        left: 22%;
        bottom: -100%;
    }

    100% {
        left: 22%;
        bottom: -35%;
    }
}

@keyframes openDIbrohim {
    0% {
        right: 22%;
        bottom: -100%;
    }

    100% {
        right: 22%;
        bottom: -35%;
    }
}

@keyframes openDTatiana {
    0% {
        bottom: -100%;
        right: -2%;
    }

    100% {
        bottom: -35%;
        right: -2%;
    }
}

@keyframes openDViktoria {
    0% {
        bottom: -100%;
        left: -5%;
    }

    100% {
        bottom: -35%;
        left: -5%;
    }
}

@keyframes openDAbdukhamid {
    0% {
        right: 10%;
        bottom: -100%;
    }

    100% {
        right: 10%;
        bottom: -35%;
    }
}

/*------------- CLOSE -------------*/

@keyframes closeDMansur {
    0% {
        left: 9%;
        bottom: -35%;
    }

    100% {
        left: 9%;
        bottom: -100%;
    }
}

@keyframes closeDDirector {
    0% {
        bottom: -35%;
        left: 40%;
    }

    100% {
        bottom: -100%;
        left: 40%;
    }
}

@keyframes closeDXursand {
    0% {
        left: 22%;
        bottom: -35%;
    }

    100% {
        left: 22%;
        bottom: -100%;
    }
}

@keyframes closeDIbrohim {
    0% {
        right: 22%;
        bottom: -35%;
    }

    100% {
        right: 22%;
        bottom: -100%;
    }
}

@keyframes closeDTatiana {
    0% {
        bottom: -35%;
        right: -2%;
    }

    100% {
        bottom: -100%;
        right: -2%;
    }
}

@keyframes closeDViktoria {
    0% {
        bottom: -35%;
        left: -5%;
    }

    100% {
        bottom: -100%;
        left: -5%;
    }
}

@keyframes closeDAbdukhamid {
    0% {
        right: 10%;
        bottom: -35%;
    }

    100% {
        right: 10%;
        bottom: -100%;
    }
}

/*------------- ANIMATIONS --------------*/

@keyframes switchLeft {
    0% {}

    100% {}
}

@keyframes switchRight {
    0% {}

    100% {}
}

@keyframes blink {
    0% {
        opacity: 0.8;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 0.8;
    }
}

@keyframes move {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 8px);
    }

    100% {
        transform: translamovete(0, 0);
    }
}

@keyframes moveX {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0.5vw, 0px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes moveXLeft {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(-0.5vw, 0px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes action {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }

}

@keyframes visible {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}